.section-client {
  display: flex;
  flex-direction: column;
  align-items: center;
  .container-client {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    max-width: 880px;
    padding: 6.563rem 12.5rem;
    @media (max-width: 1239px) {
      padding: 6.563rem 2rem;
    }
    @media (max-width: 599px) {
      padding: 4.688rem 1rem;
    }
    .title {
      text-align: center;
      font-family: "Jost", sans-serif;
      color: #1a1b24;
    }
    .client-list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 12px;
      @media (max-width: 1239px) {
        grid-template-columns: repeat(4, 1fr);
      }
      @media (max-width: 599px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
      }
      .client-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 9px;
        width: 167px;
        height: 167px;
        background-color: #314ef50a;
        @media (max-width: 1239px) {
          width: 161px;
          height: 161px;
        }
        @media (max-width: 599px) {
          width: 107px;
          height: 107px;
          padding: 2px;
          img {
            width: 60%;
          }
        }
      }
    }
  }
}
