nav {
  position: sticky;
  top: 0;
  background-color: #fbf8ff;
  z-index: 10;
  padding: 15px 32px;
  @media (max-width: 1239px) {
    padding: 0px 32px;
  }
}
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1239px) {
    padding: 15px 0;
  }

  .logo {
    display: flex;
    align-items: center;
    width: 35%;
    gap: 15px;
    a {
      width: 30%;
    }
    .logo-desc {
      font-size: 12px;
      font-family: "jost", sans-serif;
      color: #1a1b24;
      width: 50%;
      text-align: start;
      margin-left: 15px;
      @media (max-width: 1105px) {
        display: none;
      }
    }
  }
  a {
    text-decoration: none;
  }
  .nav-links {
    width: 65%;
    justify-content: flex-end;
    list-style: none;
    display: flex;
    gap: 6px;
    padding: 0;
    @media (min-width: 1010px) {
      width: 65%;
    }
    @media (min-width: 1025px) {
      width: 80%;
    }
    @media (max-width: 1239px) {
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      background-color: #f9f9f9;
      width: 100%;
      height: 100vh;
      text-align: center;
      padding-top: 20px;
      transition: all 0.3s ease-in;
      opacity: 0;
      visibility: hidden;
    }
    .nav-menu {
      display: flex;
      gap: 6px;
      font-family: "jost", sans-serif;
      @media (max-width: 1239px) {
        align-items: end;
        height: 100vh;
      }

      .nav-item-list {
        @media (max-width: 1239px) {
          width: calc(32% - 18px);
          margin: 0 32px;
          text-align: start;
          border-radius: 11px;
          background-color: #1a1b2418;
        }
        @media (max-width: 599px) {
          width: calc(63% - 18px);
        }
        .nav-item {
          display: flex;
          flex-direction: column;
          font-size: 16px;

          div {
            padding: 6px 9px;
          }
          .inner-item {
            color: #12131c;
            position: relative;

            &::before {
              content: "";
              position: absolute;
              bottom: 0;
              left: 10;
              height: 1px;
              width: 0%;
              background-color: #004769;
              transition: all 0.3s ease-in-out;
            }
            &:hover::before {
              width: calc(100% - 18px);
              @media (max-width: 1239px) {
                width: 0%;
              }
            }
          }
        }
        .nav-item-active {
          .inner-item {
            color: #004769;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              bottom: 0;
              left: 10;
              height: 1px;
              width: calc(100% - 18px);
              background-color: #004769;
              transition: all 0.3s ease-in-out;
              @media (max-width: 1239px) {
                width: 0%;
              }
            }
          }
        }
      }
      @media (max-width: 1239px) {
        flex-direction: column;
      }
    }
  }

  .menu-icon {
    display: none;
    cursor: pointer;
    i {
      font-style: normal;
    }
    @media (max-width: 1239px) {
      background-color: #004769;
      display: flex;
      align-items: center;
      color: white;
      border-radius: 6px;
      font-family: "jost", sans-serif;
      padding: 6px 9px;
      border: none;
    }
    .minus {
      transition: all 0.3s ease-in;
      transform: rotate(45deg);
    }
    .plus {
      transition: all 0.3s ease-in;
    }
  }
}

.contact-btn {
  display: flex;
  flex-direction: column;
  height: 35px;
  overflow: hidden;
  align-self: center;
  @media (max-width: 1239px) {
    margin-top: 65px;
    overflow: visible;
    align-items: end;
    width: 100%;
  }
  @media (max-width: 599px) {
    margin-top: 46px;
  }
  button {
    display: flex;
    border: none;
    border-radius: 6px;
    font-family: "jost", sans-serif;
    font-size: 16px;
    padding: 6px 9px;
    cursor: pointer;
    gap: 6px;
    justify-content: center;
    transition: all 0.25s ease-out;
    @media (max-width: 1239px) {
      color: #ffffff;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
  }
  .inner-btn {
    background-color: transparent;
    @media (max-width: 1239px) {
      background-color: #004769;
      border-radius: 11px;
      height: 93px;
      width: calc(30% - 3px);
      margin: 0 32px;
    }
    @media (max-width: 599px) {
      width: calc(60% - 9px);
    }
    .arrow {
      fill: #12131c;
      @media (max-width: 1239px) {
        fill: #ffffff;
        align-self: end;
        width: 40px;
        height: 40px;
      }
    }
  }
  .outer-btn {
    color: #ffffff;
    background-color: #004769;
    @media (max-width: 1239px) {
      display: none;
    }
  }
}

.contact-btn:hover {
  .inner-btn {
    transform: translateY(-100%);
    @media (max-width: 1239px) {
      transform: translateY(0%);
    }
  }
  .outer-btn {
    transform: translateY(-100%);
    @media (max-width: 1239px) {
      transform: translateY(0%);
    }
  }
}

@media (max-width: 1239px) {
  .nav-links.active {
    opacity: 1;
    visibility: visible;
  }
}
