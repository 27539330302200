.head-container {
  width: 100%;
  background-image: url("../../media/header.webp");
  background-size: cover;
  display: flex;
  align-items: end;
  height: 92vh;
  @media (max-width: 1239px) {
    height: 80vh;
  }
  .welcome-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: width 0.3s ease-in-out;
    width: 100%;
    padding: 2.5rem 12.5rem;
    @media (max-width: 1239px) {
      width: 90%;
      padding: 2.5rem 2rem;
    }
    @media (max-width: 599px) {
      width: 90%;
      padding: 2.5rem 1rem;
    }

    .headline {
      width: 100%;
      max-width: 880px;
      font-size: 70px;
      font-weight: 400;
      font-family: "Jost", sans-serif;
      color: #c1c7ff;
      margin: 0;
      align-self: center;
      @media (max-width: 599px) {
        font-size: 56px;
      }
    }
  }
}
