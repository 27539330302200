.testimoni-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Jost", sans-serif;
  padding: 6.563rem 0;
  gap: 40px;
  @media (max-width: 599px) {
    padding: 4.688rem 0;
    gap: 28px;
  }

  .titlecenter {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: 599px) {
      display: flex;
      justify-content: center;
    }
    .title {
      font-size: 56px;
      font-weight: 400;
      color: #0027c0;
      margin: 0;
      @media (max-width: 599px) {
        width: 90%;
        text-align: center;
        font-size: 28px;
      }
    }
  }
  .slider {
    height: 370px;
    width: 100%;
    display: flex;
    justify-content: center;

    .arrowspace {
      width: 5%;
      z-index: 7;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1440px) {
        width: auto;
      }
      @media (max-width: 1280px) {
        width: 10%;
      }
      @media (max-width: 1239px) {
        display: none;
      }

      .left-arrow {
        margin-left: 50px;
        @media (max-width: 1280px) {
          margin-right: -150px;
        }
        @media (max-width: 599px) {
          margin-left: 0px;
        }

        display: flex;
        position: relative;
        rotate: 180deg;
        border: 1px solid #1a1b24;
        border-radius: 4px;
        width: 27px;
        height: 24px;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        z-index: 10;
        cursor: pointer;
        .inner-left-arrow {
          position: absolute;
          transition: all 0.25s ease-out;
          left: 5px;
        }
        .outer-left-arrow {
          position: absolute;
          right: 30px;
          transition: all 0.25s ease-out;
        }
      }
      .left-arrow:hover {
        .inner-left-arrow {
          left: 30px;
        }
        .outer-left-arrow {
          right: 5px;
        }
      }
      .right-arrow {
        margin-right: 50px;
        display: flex;
        position: relative;
        border: 1px solid #1a1b24;
        border-radius: 4px;
        width: 27px;
        height: 24px;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        cursor: pointer;
        @media (max-width: 599px) {
          margin-right: 0px;
        }
        @media (max-width: 1280px) {
          margin-left: -150px;
        }
        .inner-right-arrow {
          position: absolute;
          transition: all 0.25s ease-out;
          left: 5px;
        }
        .outer-right-arrow {
          position: absolute;
          right: 30px;
          transition: all 0.25s ease-out;
        }
      }
      .right-arrow:hover {
        .inner-right-arrow {
          left: 30px;
        }
        .outer-right-arrow {
          right: 5px;
        }
      }
    }

    .slider2 {
      width: 50%;
      @media (max-width: 1239px) {
        width: 90%;
      }
      .slick-slide {
        > div {
          // automatically generated 'div' by 'react-slick'
          padding-inline: 100px;
          @media (max-width: 1239px) {
            padding-inline: 20px;
          }
          @media (max-width: 599px) {
            padding-inline: 5px;
          } //  space between slides / 2
        }
      }
      .slick-slider {
        .slick-list {
          overflow: visible;
        }
      }
      .slidback {
        padding: 20px 0;
        width: 100% !important;
        height: 330px;
        background-color: #efeeff;
        border-radius: 25px;
        display: flex !important;
        justify-content: center;
        @media (max-width: 1239px) {
          height: 380px;
        }

        .sliderspacing {
          width: 80%;
          .slidertext {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .testimoni {
              font-size: 19px;
            }
            .footertestimoni {
              display: flex;
              align-items: end;
              justify-content: space-between;
              .person {
                width: 60%;
                .personname {
                  font-size: 19px;
                }
                .persontitle {
                  opacity: 40%;
                  color: #1a1b24;
                }
              }
              .logo {
                width: 30%;
                display: flex;
                justify-content: end;
                img {
                  @media (max-width: 1239px) {
                    width: 70px;
                  }
                  @media (max-width: 599px) {
                    width: 100px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .buttonmob {
    @media (min-width: 1240px) {
      display: none;
    }
    @media (max-width: 1239px) {
      display: flex;
      justify-content: center;
      margin-top: 45px;
      width: 100%;
      gap: 25px;

      .arrowspace {
        z-index: 7;
        display: flex;
        align-items: center;
        justify-content: center;

        .left-arrow {
          display: flex;
          position: relative;
          rotate: 180deg;
          border: 1px solid #1a1b24;
          border-radius: 4px;
          width: 27px;
          height: 24px;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          z-index: 10;
          cursor: pointer;
          .inner-left-arrow {
            position: absolute;
            transition: all 0.25s ease-out;
            left: 5px;
          }
          .outer-left-arrow {
            position: absolute;
            right: 30px;
            transition: all 0.25s ease-out;
          }
        }
        .left-arrow:hover {
          .inner-left-arrow {
            left: 30px;
          }
          .outer-left-arrow {
            right: 5px;
          }
        }
        .right-arrow {
          display: flex;
          position: relative;
          border: 1px solid #1a1b24;
          border-radius: 4px;
          width: 27px;
          height: 24px;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          cursor: pointer;

          .inner-right-arrow {
            position: absolute;
            transition: all 0.25s ease-out;
            left: 5px;
          }
          .outer-right-arrow {
            position: absolute;
            right: 30px;
            transition: all 0.25s ease-out;
          }
        }
        .right-arrow:hover {
          .inner-right-arrow {
            left: 30px;
          }
          .outer-right-arrow {
            right: 5px;
          }
        }
      }
    }
  }
}
